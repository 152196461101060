<template>
    <div class="px-2 max-label row ma-0 mt-5" style="justify-content: space-between;">
        <!-- <span style="color:gold; font-weight:500">{{userInfo&& userInfo.balance ? _formatCurrency(userInfo.balance):''}}</span> 
        <span class="format-currency" style="font-family:'khmer mef1'"> {{ userInfo && userInfo.balance ? _displayCurrencySymbol( userInfo.account_currency ) : '' }}</span> -->
        <v-spacer></v-spacer>
        <p v-if="passSelectedChannel == 1 || passSelectedChannel == 2 || passSelectedChannel ==3 ||passSelectedChannel == 4 ||passSelectedChannel == 15 ||passSelectedChannel == 16"  class="text-right">
            {{ `${$t('title.min')}: ${passWeight.amount_min.toLocaleString("en")}  
             | ${$t('title.max')}: ${passWeight.amount_max.toLocaleString("en")}` }}
        </p>
        <p v-else class="text-right" >
            {{ `${$t('title.min')}: ${amountMin.toLocaleString("en")}  
             | ${$t('title.max')}: ${amountMax.toLocaleString("en")}` }}
        </p>
    </div>
</template>

<script>
import "@/assets/css/homepage.css";
import { mapGetters } from "vuex";
import Mixin from "../mixin/index";
export default {
    props: ['passWeight'],
    data() {
        return {
            mixins: [Mixin],
        }
    },
    computed: {
        ...mapGetters("$_modules", {
            userInfo: "getUserInfo",
            passSelectedChannel: "getChannelId"
        }),
        ...mapGetters("$_dashboard", {
            amountMin: "getAmountMin",
            amountMax: "getAmountMax",
        }),
    },
}
</script>
<style scoped>
.max-label{
    width:100%; 
    height:30px; 
    margin-bottom:-9px;
}
.format-currency{
    font-size:1.2rem;
    margin-top : -2px;
    font-weight: 500;
    color:gold;
    margin-left:1px;
}
</style>