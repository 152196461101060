export default {
    permission_list: 'Permission List',
    role_list: 'Role List',
    user_list: 'User List',
    menu_list: 'Menu List',
    create_permission: 'Create Permission',
    update_permission: 'Update Permission',
    create_role: 'Create Role',
    update_role: 'Update Role',
    create_user: 'Create User',
    update_user: 'Update User',
    create_menu: 'Create Menu',
    update_menu: 'Update Menu',
    change_password_user: 'Change Password User',
    welcome: 'Welcome:',
    account_balance: 'Account Balance',
    user_role: 'User Role',
    No:'No',
    your_balance:'Your Balance',
    home:'Home',
    sbc:'SBC',
    status:'Status',
    time:'Time',
    result:'Result',
    five_k:'1.5 $',
    ten_k:'2.5 $',
    twenty_k:'5 $',
    five_k1:'12.5 $',
    ten_100k:'25 $',
    twenty_200k:'50 $',
    five_500k:'125 $',
    one_1m:'1M $',
    payout:'Payout',
    current_betting:'BETTING',
    today_report:'REPORT',
    type:'Type',
    print:"Print",
    amount:'Amount',
    win_lose:"Win/Lose",
    betLimit:'Bet Limit',
    pleaseBet:'Please Bet',
    action:'Action',
    my_profile:'My Profile',
    persional_info:'PERSONAL',
    change_password:'CHANGE PASSWORD',
    result_betting:'RESULT BETTING',
    result_win:'RESULT WIN',
    report:'REPORT',
    MERON:'MERON',
    c_MERON:'MERON',
    c_WALA:'WALA',
    c_TIE:"TIE",
    c_CANCEL:"CANCEL",
    WALA:'WALA',
    boxing_MERON:'RED',
    boxing_WALA:'BLUE',
    boxing_TIE:'DRAW',
    boxing_CANCEL:"CANCEL",
    roulette_MERON:'RED',
    roulette_WALA:'BLACK',
    roulette_CANCEL:'CANCEL',
    TIE:"TIE",
    roulette_TIE:'GREEN',
    login:'LOGIN',
    deposit:'Deposit',
    withdraw:'Withdraw',
    betting:'Statement',
    all_betting:'Statement(All)',
    betting1:'BET',
    channel1:'CHANNEL 1',
    channel2:'CHANNEL 2',
    channel3:'CHANNEL 3',
    channel4:'CHANNEL 4',
    min: 'MIN',
    max: 'MAX',
    log:'Log in',
    logging: 'Logging in',
    changeLanguage :'Change Language',
    password: 'Enter your password',
    SignIn: "Sign In",
    bet_between:"Range",
    bet_small_large: 'Small / Big',
    all_statement: 'All Statement',
    total_win_lose: 'Total Win/Lose',
    start_date: 'Start Date',
    end_date: 'End Date',
    fight_no: 'Fight No',
    channel: 'Channel',
    group_no: 'Group No',
    date_time: 'Date Time',
    cast: 'Cast',
    amount_win: 'Amount Win',
    total_amount: 'Total Amount',
    // start from here
    TIGER: "Tiger",
    DRAGON:"Dragon",
    BANKER:"BANKER",
    PLAYER:"PLAYER",
    gameIsClose:"Game is close",
    tie:"TIE",
    draw:"DRAW",
    c_draw:'TIE',
    boxing_draw:'DRAW',
    roulette_draw:'GREEN',
    fight_sign:"#",
    fight:"Fight",
    cancel:"CANCEL",
    confirm:"CONFIRM",
    Close:"Close",
    Open:"OPEN",
    Not_Open:"Not Open",
    //sidebar
    user_center: "User Imformation",
    all_report: "All Report",
    cash_ins: "Cash Ins",
    withdrawals: "Withdrawals",
    languages: "Languages",
    other: "Other",
    logout: "Logout",
    small:"UNDER",
    large:"OVER",
    thin:"Under",
    thick:"Over",
    return:"Return",
    Old_Balance:"Old Balance",
    New_Balance:"New Balance",
    Amount:'Amount',
    dateTime:'Date Time',
    cash:'Cash',
    Rdeposit:'Deposit Report',
    Rwithdraw:'Withdraw Report',
    red:'Red',
    blue:'Blue',
    even: 'EVEN',
    odd: 'ODD',
    selectCoin : "Please Select Coin",
    _result:"Result= ",
    ticket:'Ticket',
    no_data: "No data ...",
    total_betting:'Outstanding',
    total:"Total",
    credit: "Credit",
    outstanding:"Outstanding",
    previous:"previous",
    lo_current_betting:"Current Betting",
    lo_previous_betting:"Previous Betting",
    result_year: "Result Year",
    result_small_big: "Result Small/Big",
    result_range: "Result Range",
    odd_even:'Odd/Even',
    blue_red:'Blue/Red',
    wait:"Please Wait",
    post_channel:"Post",
    go_with_tail:"Bet Tail",
    go_with_head:"Bet Head",
    normal_betting:"Normal",
    all_post:"All",
    submit_betting:"Sumit",
    lottery_result:"Lottery Result",
    game_close:"BETTING CLOSE",
    game_no:"GAME No:",
    total_betting_an_invoice:'Total In an invoice',
    total_betting_a_game:'Total In a game',
    not_enought_balance:"NOT ENOUGH BALANCE",
    please_bet:'Please Select Number to bet',
    SUCCESS:'Bettin Success',
    bet_type:"Betting Type",
    bet_total:"Total Amount",
    invoice_detail: "Invoice Detail",
    total_cast:"Total Cast",
    total_amount_win:"Total Repayment",
    x_win:"Win",
    x_lose:"Lose",
    tiger:"TIGER",
    dragon :'DRAGON',
    player :"PLAYER",
    banker :"BANKER",
    
    yuki_wipe : "Rat",
    yuki_street : "Ox",
    yuki_karl : "Tiger",
    yuki_thao : "Rabbit",
    yuki_factory : "Dragon",
    yuki_net : "Snake",
    yuki_thin : "Under",
    yuki_thick : "Over",
    yuki_mimi : "Horse",
    yuki_mom : "Goat",
    yuki_vk : "Monkey",
    yuki_roka : "Rooster",
    yuki_kor : "Pig",
    yuki_odd : "Odd",
    yuki_pair : "Pair",
    yuki_red : "Red",
    yuki_blue : "Blue",
    yuki_dog : "Dog",
    yuki_null : "null",
    win :'Win',
    lose:"Lose",
    'ខ្លា':"Tiger",
    'មាន់':'Cock',
    "ត្រី":'Fish',
    "បង្គង":"Lobster",
    'ក្ដាម':"Crab",
    'ឃ្លោក':"Gourd",
    'PAIR BANKER':"Pair Banker",
    'PAIR PLAYER':"Pair PLAYER",
    undefined:"---",
    CANCEL :"CANCEL",
    bet_point :"Bet Point",
    bac4_MERON:'BANKER',
    bac4_WALA:'PLAYER',
    bac4_TIE:'DRAW',
    bac4_draw:"DRAW",
    bac4_CANCEL:"CANCEL",
};
