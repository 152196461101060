<template>
    <div v-if="passActiveItem == passIndex" class="img-coin-range">
        <img :src="_detectCoin(passIndex)" class="betting-coin-range " />
        <div class="money-in-coin-range-position" :style="`color:${_detectColor(passIndex)} `">
            {{
                `${userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(passPlaceCoin) :
                    formatBtnCurrency(passPlaceCoin)} `
            }}</div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    props: [
        'passActiveItem',
        'passIndex',
        'passPlaceCoin',
    ],
    data() {
        return {
            selectedButton: {},
        }
    },
    computed: {
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
    },
    methods: {
        _detectColor(index) {
            switch (index) {
                case 0:
                    return 'black';
                case 1:
                    return 'black';
                case 2:
                    return 'black';
                case 3:
                    return 'black';
                case 4:
                    return 'black';
                case 5:
                    return 'black';
                case 6:
                    return 'black';
                case 7:
                    return 'black';
                default:
                    return ''
            }
        },
        _detectCoin(index) {
            switch (index) {
                case 0:
                    return 'a/images/coins/grey.png';
                case 1:
                    return '/images/coins/grey.png';
                case 2:
                    return '/images/coins/grey.png';
                case 3:
                    return '/images/coins/grey.png';
                case 4:
                    return '/images/coins/grey.png';
                case 5:
                    return '/images/coins/grey.png';
                case 6:
                    return '/images/coins/grey.png';
                case 7:
                    return '/images/coins/grey.png';
                case 8:
                    return '/images/coins/grey.png';
                case 9:
                    return '/images/coins/grey.png';
                case 33:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },
        formatBtnCurrencyKh(amount) {
            switch (true) {
                case (amount >= 1000 && amount <= 9999):
                    return amount / 1000 + "ព";
                case (amount >= 10000 && amount <= 999999):
                    return amount / 10000 + "ម";
                case (amount >= 1000000 && amount <= 99999999999):
                    return amount / 1000000 + "ល";
                default:
                    return amount
            }
        },
        formatBtnCurrency(amount) {
            switch (true) {
                case (amount >= 1000 && amount <= 9999):
                    return amount / 1000 + "K";
                default:
                    return amount
            }
        },
    }
}
</script>
<style scoped>
.img-coin-range {
    width: 100%;
    display: grid;
    margin-top: -70px
}

.money-in-coin-range-position {
    color: black;
    position: absolute;
    font-size: 16px;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -51%);
    padding: 0px;
    margin: 0px !important;
}

.betting-coin-range {
    width: 100%;
    height: auto;
    margin: auto;
    
    padding: 5px;
    z-index: 1000 important;
    position: absolute;
    font-size: 16px;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;
    top: 50%;
    left: 50%;
    transform: translate(-52%, -56%);
}
</style>