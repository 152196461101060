<template>
  <!-- eslint-disable -->
  <div>
    <v-btn 
      @click="print(current._id)"
      x-small color="primary"
      id="print-button"
    >
    Print
    </v-btn >
    <table :id="`printMe${current._id}`" hidden>
      <tbody>
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            Cflive
          </td>
        </tr>
        <br />
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            Bet ID: {{ current._id }}
          </td>
        </tr>
        <br />
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            -------------------------------------
          </td>
        </tr>
        <br />
        <tr>
          <td>
            <table>
              <tbody>
                <tr>
                  <td style="padding: 5px; text-align: left">Fight No:</td>
                  <td style="padding: 5px; text-align: left">
                    {{ current.fight_no ? current.fight_no : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">Bet Type:</td>
                  <td style="padding: 5px; text-align: left">
                    {{ current.betting ?  $t(sameGameFindName(passSelectedChannel) + current.betting): "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">Date:</td>
                  <td style="padding: 5px; text-align: left">
                    {{current.time}}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">Bet Point:</td>
                  <td style="padding: 5px; text-align: left">
                    <span>{{current.amount}}</span>
                  </td>
                </tr>
                <!-- <tr>
                  <td style="padding: 5px; text-align: left">Win:</td>
                  <td style="padding: 5px; text-align: left">
                    {{
                      current.amount_win || current.amount_win === 0
                        ? parseInt(current.amount_win).toLocaleString("en")
                        : "???"
                    }}
                  </td>
                </tr> -->
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            -------------------------------------
          </td>
        </tr>
        <br />
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable */
import {  mapGetters} from "vuex";
import Mixin from "../../../mixin/index.js";
export default {
  props: ["current"],
  data() {
    return {

      mixins: [Mixin],
    };
  },
  computed: {
        ...mapGetters("$_modules", {
          passSelectedChannel: "getChannelId"
        }),

    },
  methods: {
    async print(id) {
      // Pass the element id here
      await this.$htmlToPaper(`printMe${id}`);
    },
  },
};
</script>
