<template>
    <div class="history limit-on-tab">
        <v-tabs style="color: white !important;" fixed-tabs>
            <v-tabs-slider color="yellow" style="height:3px !important"></v-tabs-slider>
            <v-tab @click="callCurrentBetting" class="g-header">
                <!-- <v-icon left style="color:white"> mdi mdi-cast </v-icon> -->
                <i class="bi-coin" style="font-size: 1.5rem; color: black; padding: 5px;"></i>
                <span style="font-family: 'khmer mef1'; color:black; font-weight:bold">
                    {{ $t("title.current_betting") }}
                </span>
            </v-tab>
            <v-tab @click="callTodayReport" class="g-header">
                <!-- <v-icon left style="color:white"> mdi-file-move-outline </v-icon> -->
                <i class="bi-clipboard-data" style="font-size: 1.5rem; color: black; padding: 5px;"></i>
                <span style="font-family: 'khmer mef1'; color:black; font-weight:bold">
                    {{ $t("title.today_report")}}
                </span>
            </v-tab>
            <v-tab-item>
                <v-simple-table fixed-header height="279.1" min-height="279.1" style="background: #ffffff;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    {{ $t("title.fight_sign") }}
                                </th>
                                <th class="text-left">
                                    {{ $t("title.type") }}
                                </th>
                                <th class="text-left">
                                    {{ $t("title.return") }}({{userInfor.currencySymbol}})
                                </th>
                                <th class="text-left">
                                    {{ $t("title.action") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="current in currentBetting.list" :key="current._id">
                                <td>
                                    #{{current.fight_no}}
                                </td>
                                <td>
                                    <div v-if="current.year_number === 13" style="color:#ffba41">
                                        Under
                                    </div>
                                    <div v-else-if="current.year_number === 14" style="color:#3a6305">
                                        Over
                                    </div>
                                    <div v-else-if="current.year_number === 20" style="color:#0000ff">
                                        Blue
                                    </div>
                                    <div v-else-if="current.year_number === 19" style="color:#ff0000">
                                        Red
                                    </div>
                                    <!-- <div :style="{ color: current.bet_color }" v-if="current.year_number > 12">
                                        {{ current.betting }}
                                    </div> -->
                                    <v-img v-else :src="findImageOfEachYearByName(current.betting)" width="35" style="text-align: center;margin-bottom: 4px; object-fit: cover; height: 35px;"></v-img>
                                </td>
                                <td :class="'c' + current.bet_color">
                                    {{ current.amount }}
                                </td>
                                <td>
                                    <print-betting :current="current" />
                                </td>
                            </tr>
                        </tbody>
                        <!-- <div class="py-3 text-center gp-panel-bg mx-2">
                            <div class="bet d-flex my-2 align-items-center " v-for="today in passCurrentBetting.list" :key="today._id">
                                <div>
                                    <span class="fight-circle font-weight-bold " :class="'report-' + today.bet_color">
                                        #{{ today.fight_no }}
                                    </span>
                                </div>
                                <div class="flex-grow-1 pl-0 pr-2 px-sm-2 w-100">
                                    <div class="d-flex justify-content-between align-items-center text-white">
                                        <div class="pl-1">
                                            {{ today.amount }}
                                        </div>
                                        <div class="d-flex flex-column align-items-end">
                                            <div class="d-inline " :class="today.betting == 'WALA' ? 'text-wala' : 'text-meron'">
                                                {{ today.betting }}
                                            </div>
                                            <small class="font-italic text-muted">
                                                {{ today.time }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </template>
                </v-simple-table>
            </v-tab-item>
            <v-tab-item>
                <v-simple-table fixed-header height="279.1" min-height="279.1" style="background: #ffffff;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    {{ $t("title.fight_sign") }}
                                </th>
                                <th class="text-left">
                                    {{ $t("title.time") }}
                                </th>
                                <th class="text-left">
                                    {{ $t("title.type") }}
                                </th>
                                <th class="text-left">
                                    {{ $t("title.return") }}({{userInfor.currencySymbol}})
                                </th>
                                <th class="text-left">
                                    {{ $t("title.result") }}
                                </th>
                                <th class="text-left">
                                    {{ $t("title.action") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="today in todayBetting" :key="today._id">
                                <th class="text-left">
                                    #{{ today.fight_no }}
                                </th>
                                <th class="text-left">
                                    {{ grtDate(today.time) }}
                                </th>
                                <th class="text-left">
                                    <div v-if="today.year_number == 13" style="color:#ffba41">
                                        Under
                                    </div>
                                    <div v-else-if="today.year_number == 14" style="color:#3a6305">
                                        Over
                                    </div>
                                    <div v-else-if="today.year_number == 20" style="color:#0000ff">
                                        Blue
                                    </div>
                                    <div v-else-if="today.year_number == 19" style="color:#ff0000">
                                        Red
                                    </div>
                                    <!-- <div :style="{ color: today.bet_color }" v-if="today.year_number > 12">
                                        {{ today.betting }}
                                    </div> -->
                                    <v-img v-else :src="findImageOfEachYear(today.year_number)" width="35" style="text-align: center;margin-bottom: 4px; object-fit: cover; height: 35px;"></v-img>
                                </th>
                                <th :class="
                                          today.bet_result.is_win == null ? 'c' + today.result_color : today.bet_result.is_win ? 'ccir-green' : 'ccir-red'
                                        " class="text-left">
                                    {{ today.amount }}
                                </th>
                                <th class="text-left">
                                    <div v-if="today.bet_result.year_number === '13'" style="color:#ffba41">
                                        Under
                                    </div>
                                    <div v-else-if="today.bet_result.year_number === '14'" style="color:#3a6305">
                                        Over
                                    </div>
                                    <div v-else-if="today.bet_result.year_number === '20'" style="color:#0000ff">
                                        Blue
                                    </div>
                                    <div v-else-if="today.bet_result.year_number === '19'" style="color:#ff0000">
                                        Red
                                    </div>
                                    <v-img v-else :src="findImageOfEachYearByName(today.bet_result.name)" width="35" style="text-align: center;margin-bottom: 4px; object-fit: cover; height: 35px;"></v-img>
                                </th>
                                <th class="text-left">
                                    <print-today-report :todayReport="today" />
                                </th>
                            </tr>
                        </tbody>
                        <!-- <div class="py-3 text-center gp-panel-bg mx-2">
                            <div class="bet d-flex my-2 align-items-center " v-for="today in passTodayReport"
                                :key="today._id">
                                <div><span class="fight-circle font-weight-bold " :class="'report-' + today.bet_color">
                                        # {{ today.fight_no }}
                                    </span></div>
                                <div class="flex-grow-1 pl-0 pr-2 px-sm-2 w-100">
                                    <div class="d-flex justify-content-between align-items-center text-white">
                                        <div class="pl-1" :class="today.is_win ? 'text-wala' : today.is_win == false ? 'text-meron' :'txt-white'">{{ today.amount }}</div>
                                        <div class="d-flex flex-column align-items-end">
                                            <div class="d-inline " :class="today.is_win ? 'text-wala' : today.is_win == false ? 'text-meron' : today.result_name =='TIE' ? 'txt-gold' : 'txt-grey'">
                                                {{ today.is_win ? 'Win' : today.is_win == false ? 'Lose' : today.result_name =='TIE' ? 'Draw' : 'Cancelled' }}
                                            </div>
                                            <small class="font-italic text-muted">
                                                {{ today.time }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </template>
                </v-simple-table>
            </v-tab-item>
        </v-tabs> 
    </div>
</template>
<script>
import "@/assets/css/video-js.css";
import "@/assets/css/homepage.css";
import { mapActions, mapGetters} from "vuex";
import PrintBetting from "../PrintBetting";
import PrintTodayReport from "../PrintTodayReport";
export default {
    props: ['passLoadingTodayReport'],
    components: {
        PrintBetting,
        PrintTodayReport
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
        ...mapGetters("$_dashboard_live", {
            todayBetting: "getTodayBetting",
            currentBetting: "getCurrentBetting"
        }),
    },
    methods: {
        grtDate(date){
            let time = date.split(" ")
            if(time[1]){
                return time[1];
            }
            return date
        },
        findImageOfEachYear(yearId) {
            switch (yearId) {
            case 1:
                return require("/public/images/yuki/y1o.png");
            case 2:
                return require("/public/images/yuki/y2o.png");
            case 3:
                return require("/public/images/yuki/y3o.png");
            case 4:
                return require("/public/images/yuki/y4o.png");
            case 5:
                return require("/public/images/yuki/y5o.png");
            case 6:
                return require("/public/images/yuki/y6o.png");
            case 7:
                return require("/public/images/yuki/y7o.png");
            case 8:
                return require("/public/images/yuki/y8o.png");
            case 9:
                return require("/public/images/yuki/y9o.png");
            case 10:
                return require("/public/images/yuki/y10o.png");
            case 11:
                return require("/public/images/yuki/y11o.png");
            case 12:
                return require("/public/images/yuki/y12o.png");
            default:
                return ""
            // code block
            }
        },
        findImageOfEachYearByName(yearName) {
            switch (yearName) {
            case 'Rat':
                return require("/public/images/yuki/y1o.png");
            case 'Ox':
                return require("/public/images/yuki/y2o.png");
            case 'Tiger':
                return require("/public/images/yuki/y3o.png");
            case 'Rabbit':
                return require("/public/images/yuki/y4o.png");
            case 'Dragon':
                return require("/public/images/yuki/y5o.png");
            case 'Snake':
                return require("/public/images/yuki/y6o.png");
            case 'Horse':
                return require("/public/images/yuki/y7o.png");
            case 'Goat':
                return require("/public/images/yuki/y8o.png");
            case 'Monkey':
                return require("/public/images/yuki/y9o.png");
            case 'Rooster':
                return require("/public/images/yuki/y10o.png");
            case 'Dog':
                return require("/public/images/yuki/y11o.png");
            case 'Pig':
                return require("/public/images/yuki/y12o.png");
            default:
                return ''
            // code block
            }
        },
        async callCurrentBetting() {
            await this.fetchCurrentBetting();
        },
        async callTodayReport() {
            await this.fetchTodayBetting();
        },
        _sentRefreshTodayReport() {
            this.$emit("sentRefreshTodayReport");
        },
        _sentRefreshCurrentReport() {
            this.$emit("sentRefreshCurrentReport");
        },
        ...mapActions("$_dashboard_live", [
            "fetchTodayBetting",
            "fetchCurrentBetting"
        ]),
    }
}
</script>
<style>
    /* width */
    ::-webkit-scrollbar {
        width: 5px
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: rgba(9, 17, 40, .7)
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background-color: rgba(40, 53, 89, .8);
        border-radius: 10px
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(78, 106, 181, .2)
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg)
        }
        to {
            transform: rotate(360deg)
        }
    }
    .refresh-start {
        animation-name: rotate;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-play-state: running;
    }
    .text-meron {
        color: #f93c3c!important
    }
 
    .txt-white{
        color:rgb(255, 255, 255,0.5)
    }
    .txt-gold{
        color:#FFC008
    }
    .txt-grey{
        color:#6C757D
    }
</style>