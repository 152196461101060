<template>
    <div class="view-bet-sec pt-0 px-0 pb-2 px-md-0 pt-1">
        <ChannelInfo
            :passChannel="passChannel"
            :passTotalLotto="totalLotto"
        />
        <div>
            <div class="g-summary pb-2">
                <div class="sides-wrapper gp-panel-bg rounded mb-3">
                    <div class="d-flex sides px-2 justify-center w-100 mb-3">
                        <div v-if="smallButton" class="side-section wala-section pb-1 side-1 mr-5"
                            :class="selectedBtnClassSmall ? 'on-active-btn' : ''"
                            @click="_allowBtnBet('selectedsmall', smallButton)"
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''"
                            >
                            <div class=" pt-12 mb-0 "><span style="font-weight:600">{{
                            userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(passCurrentBetting.small_amount) : formatBtnCurrency(passCurrentBetting.small_amount) 
                            }}</span>
                                <div class="odds">{{ smallButton?' '+ smallButton.payout_display: 'x.xx'}}</div>
                                <div class="side-title pt-1">
                                    <div class="truncate font-weight-bold">{{ smallButton ? $t('title.'+smallButton.name) : ''}}</div>
                                    <div class="range-betting-payout font-weight-bold">{{ ` ${ smallButton.start_number } -  ${ smallButton.end_number }` }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="side-section meron-section pb-1 side-1"
                            :class="selectedBtnClasslarge ? 'on-active-btn' : ''" v-if="largeButton"
                            @click="_allowBtnBet('selectedlarge')"
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''">

                            <div class="pt-12 mb-0"><span style="font-weight:600">{{
                            userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(passCurrentBetting.large_amount) : formatBtnCurrency(passCurrentBetting.large_amount) 
                            }}</span>

                                <div class="odds">{{ largeButton?' '+ largeButton.payout_display: 'x.xx'}}</div>
                                <div class="side-title pt-1">
                                    <div class="truncate font-weight-bold">{{ largeButton ? $t('title.'+largeButton.name) : ''}}</div>
                                    <div class="range-betting-payout font-weight-bold">{{ ` ${ largeButton.start_number } -  ${ largeButton.end_number }` }}</div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div v-if="oneButton" class=" row w-100 justify-center mx-0 mt-2">
                        <div class="side-section-range large-section pb-1 side-1 range-betting-container"
                            :class="selectedBtnClassOne? 'range-betting-container-active' : ''"
                            @click="_allowBtnBet('selectedOne')"
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''">
                            <div class=" text-center range-betting-header">
                                {{ oneButton.name }}
                            </div>
                            <div class="range-betting-payout font-weight-bold">{{ `x ${ oneButton.payout_display } ` }}</div>
                            <div class="range-betting-payout font-weight-bold">{{ ` ${ oneButton.start_number } -  ${ oneButton.end_number }` }}</div>
                            
                        </div>
                        <div v-if="twoButton"
                            class="side-section-range large-section pb-1 side-1 range-betting-container mx-1"
                            :class="selectedBtnClassTwo? 'range-betting-container-active' : ''"
                            @click="_allowBtnBet('selectedTwo')"
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''">
                            <div class=" text-center range-betting-header">
                                {{ twoButton.name }}
                            </div>
                            <div class="range-betting-payout font-weight-bold">{{ `x ${ twoButton.payout_display } ` }}</div>
                            <div class="range-betting-payout font-weight-bold">{{ ` ${ twoButton.start_number } -  ${ twoButton.end_number }` }}</div>
                            
                        </div>
                        <div v-if="threeButton"
                            class="side-section-range large-section pb-1 side-1 range-betting-container"
                            :class="selectedBtnClassThree? 'range-betting-container-active' : ''"
                            @click="_allowBtnBet('selectedThree')"
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''">
                            <div class=" text-center range-betting-header">
                                {{ threeButton.name }}
                            </div>
                            <div class="range-betting-payout font-weight-bold">{{ `x ${ threeButton.payout_display } ` }}</div>
                            <div class="range-betting-payout font-weight-bold">{{ ` ${ threeButton.start_number } -  ${ threeButton.end_number }` }}</div>
                            
                        </div>
                        <div v-if="fourButton"
                            class="side-section-range large-section pb-1 side-1 range-betting-container mx-1"
                            :class="selectedBtnClassFour? 'range-betting-container-active' : ''"
                            @click="_allowBtnBet('selectedFour')"
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''">
                            <div class=" text-center range-betting-header">
                                {{ fourButton.name }}
                            </div>
                            <div class="range-betting-payout font-weight-bold">{{ `x ${ fourButton.payout_display } ` }}</div>
                            <div class="range-betting-payout font-weight-bold">{{ ` ${ fourButton.start_number } -  ${ fourButton.end_number }` }}</div>
                            
                        </div>
                        <div v-if="fiveButton"
                            class="side-section-range large-section pb-1 side-1 range-betting-container"
                            :class="selectedBtnClassFive? 'range-betting-container-active' : ''"
                            @click="_allowBtnBet('selectedFive')"
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''">
                            <div class=" text-center range-betting-header">
                                {{ fiveButton.name }}
                            </div>
                            <div class="range-betting-payout font-weight-bold">{{ `x ${ fiveButton.payout_display } ` }}</div>
                            <div class="range-betting-payout font-weight-bold">{{ ` ${ fiveButton.start_number } -  ${ fiveButton.end_number }` }}</div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <CoinButton 
                :passGetButton="passGetButton" 
                :passChannel="passChannel"
                :passPlaceCoin="bettingMoney"
                v-on:handleInputMonay="_inputAmount"/>
                <SubmitBtn  
            :passCheckAllowSubmit="_checkAllowSubmit"
            v-on:handleSubmitBtn="_submitBetting"
            v-on:handleClearBtn="_clearMoney"/>
            <LimitBetting/>
        </div>
    </div>
</template>

<script>
import "@/assets/css/video-js.css";
import "@/assets/css/homepage.css";
import CoinButton from '../../../../components/CoinBettingButton.vue';
import ChannelInfo from '../../../../components/ChannelInfoLive.vue';
import LimitBetting from '../../../../components/LimitBettingLive.vue';
import SubmitBtn from '../../../../components/SubmitBtn.vue';
import { mapGetters, mapMutations } from "vuex";
export default {
    components: { CoinButton, ChannelInfo, LimitBetting, SubmitBtn},
    props: ["passChannel",
        "passCurrentBetting",
        'passChannelStatus',
        'passGetButton',
        'passBettingTypeMaster',
        'passWeight',
        'passSelectedChannel'
    ],
    data() {
        return {
            selectedButton: {},
            bettingMoney: 0,
            valueTiger: "",
            resultTiger: '',
            valueDragon: '',
            resultDragon: '',
            selectedBtnClassSmall: false,
            selectedBtnClassTie: false,
            selectedBtnClasslarge: false,
            countDown: {},
            selectedBtnClassOne: false,
            selectedBtnClassTwo: false,
            selectedBtnClassThree: false,
            selectedBtnClassFour: false,
            selectedBtnClassFive: false,
            selectedCoin: false
        }

    },

    computed: {

        smallButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[0]
            else return {}
        },
        largeButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[1]
            else return {}
        },
        oneButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[2]
            else return {}
        },
        twoButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[3]
            else return {}
        },
        threeButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[4]
            else return {}
        },
        fourButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[5]
            else return {}
        },
        fiveButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[6]
            else return {}
        },
        _checkAllowSubmit() {
            if (  this.bettingMoney> 0 && 
                (this.selectedBtnClassFive || this.selectedBtnClassFour || this.selectedBtnClassThree 
                || this.selectedBtnClassTwo || this.selectedBtnClassOne || this.selectedBtnClassSmall || this.selectedBtnClasslarge)
                ) return true;
            else  return false;
        },
        ...mapGetters("$_dashboard_live", {
            //Lotto
            totalLotto: "getTotalLotto",
            amountMin: "getAmountMin",
            amountMax: "getAmountMax",
            getCountdown:"getCountdown"
        }),
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
    },

    methods: {
        
        formatBtnCurrencyKh(amount) {
            switch (true) {
                case (amount >= 1000 && amount <=9999):
                    return amount / 1000 + "ព";
                case (amount >= 10000 && amount <=999999):
                    return amount / 10000 + "ម";
                case (amount >= 1000000 && amount <=99999999999):
                    return amount / 1000000 + "ល";
                default:
                    return amount
            }
        },
        formatBtnCurrency(amount) {
            switch (true) {
                case (amount >= 1000 && amount <=9999):
                    return amount / 1000 + "K";
                default:
                    return amount
            }
        },

        _detectCurrentMoneyCoinlarge(money) {
            switch (true) {
                case money < 5:
                    return '/images/coins/blue.png';
                case money < 10:
                    return '/images/coins/pink.png';
                case money < 20:
                    return '/images/coins/cyan.png';
                case money < 50:
                    return '/images/coins/red.png';
                case money < 100:
                    return '/images/coins/orange.png';
                case money < 99999999:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyColorlarge(money) {
            switch (true) {
                case money < 5:
                    return 'black';
                case money < 10:
                    return 'black';
                case money < 20:
                    return 'black';
                case money < 50:
                    return 'black';
                case money < 100:
                    return 'black';
                case money < 99999999:
                    return 'black';
                default:
                    return ''
            }
        },
        _inputAmount(money) {
            
                this.selectedCoin = true;
                this.bettingMoney = parseInt(this.bettingMoney);
                this.bettingMoney += parseInt(money)

        },

        _clearMoney() {
            this.bettingMoney = 0;
            this.selectedBtnClassSmall = false;
            this.selectedBtnClassOne = false;
            this.selectedBtnClasslarge = false;
            this.selectedBtnClassTwo = false;
            this.selectedBtnClassThree = false;
            this.selectedBtnClassFour = false;
            this.selectedBtnClassFive = false;
            this.selectedCoin = false;
        },
        _submitBetting() {
            let submitBettingData = {
                type_id: this.selectedButton._id,
                amount: this.bettingMoney
            };
            this.$emit("sentSubmitBetting", submitBettingData);
            this._clearMoney();
        },
        _allowBtnBet(selectedBtn) {
            if (!this.selectedCoin ) {
                this.$toastr.e(this.$t('title.selectCoin'));
                return false;
            }
            switch (selectedBtn) {
                case 'selectedsmall':
                    this.selectedButton = this.smallButton;
                    this.selectedBtnClassSmall = true;
                    this.selectedBtnClasslarge = false;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;

                    break;
                case 'selectedlarge':
                    this.selectedButton = this.largeButton;
                    this.selectedBtnClasslarge = true;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    break;
                case 'selectedOne':
                    this.selectedButton = this.oneButton;
                    this.selectedBtnClassOne = true;
                    this.selectedBtnClasslarge = false;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    break;
                case 'selectedTwo':
                    this.selectedButton = this.twoButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClasslarge = false;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClassTwo = true;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    break;
                case 'selectedThree':
                    this.selectedButton = this.threeButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClasslarge = false;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = true;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    break;
                case 'selectedFour':
                    this.selectedButton = this.fourButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClasslarge = false;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = true;
                    this.selectedBtnClassFive = false;
                    break;
                case 'selectedFive':
                    this.selectedButton = this.fiveButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClasslarge = false;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = true;
                    break;
            }
        },
        ...mapMutations("$_dashboard_live", [
            "BETTING_DIALOG",
            "UPDATE_LOTTO_CARD"
        ]),
    }
}
</script>
<style scoped>
@media(max-width:768px) {
    .money-in-coin-range-position {
        margin-top: -34px;
        margin-bottom: 14px;
        z-index: 100;
        font-weight: 900;
        width: 100%;
        text-align: center;
        font-size: 12px;
        text-shadow: 1px 0px black;
    }
    .betting-coin-range {
        width: 50px;
        height: auto;
        margin: auto;
        margin-top: -50px;
        z-index:1000 important
     
    }
    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
       background-color: #534e4970;;
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
       background-color: #534e4970;
    }

    .betting-coin-small {
        width: 50px;
        height: auto;
        margin: auto;
        margin-top: -100px;
    }

    .betting-coin-large {
        width: 50px;
        height: auto;
        margin: auto;
        margin-top: -100px;
    }

    .btn-coin {
        width: 45px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

   .btn-submit-money {
    background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
        justify-content: center;
    }

    .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }


    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
        justify-content: center;
    }

    .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position {
        margin-top: -84px;
        margin-bottom: 25px;
        margin-left: -2px;
        z-index: 100;
        font-weight: 600;
        text-shadow: 1px 0px 1px black;
        font-size: 13px;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        top: 1px;
        left: -16px;
        font-size: 12.5px;
        font-weight: 600;
        text-shadow: 1px 0px 1px black;
        width: 70px;
    }
}

@media(min-width:768px) {

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
       background-color: #534e4970;;
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
       background-color: #534e4970;
    }

    .betting-coin-large {
        width: 50px;
        height: auto;
        margin: auto;
        margin-top: -100px;
    }

    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
        justify-content: center;
    }

    .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .betting-coin-small {
        width: 50px;
        height: auto;
        margin: auto;
        margin-top: -100px;
    }
    .betting-coin-range {
        width: 50px;
        height: auto;
        margin: auto;
        margin-top: -50px;
        
    }

    .betting-coin-large {
        width: 60px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
        justify-content: center;
    }

    .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position {
        margin-top: -84.5px;
    margin-bottom: 15px;
    z-index: 100;
    font-size: 14px;
    font-weight: 900;
    text-shadow: 1px 0 0 black;
    }
    .money-in-coin-range-position {
        margin-top: -34px;
        margin-bottom: 15px;
        z-index: 100;
        font-weight: 900;
        width: 100%;
    text-align: center;
    font-size: 12px;
    text-shadow: 1px 0px black;
    }

    .btn-bet-circle {
        position:absolute;
        top: 7px;
    /* left: 6%; */
    font-size: 16px;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;
    text-align: center;
    width: 53px;
    }

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
       background-color: #534e4970;;
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
       background-color: #534e4970;
    }
}

.open-betting-countdown {
    background-color: #003366;
    font-size: 22px;
    font-family: 'khmer mef1';
    font-weight: 900;
    height: 42px;
    text-align: center;
    padding-top: 6px;
    text-shadow: 0.2px 0.2px red;
}

.open-betting {
    color: #28a745;
}

.close-betting {
    color: #dc3545;
}
.range-betting-container{
    width:18%; height:90px; border-radius: 5px; background-color: rgb(0, 102, 153, .5); border: 1px solid rgb(218, 165, 32);
}
.range-betting-container-active{
    width:18%; height:90px;
    border-radius: 5px;
    /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
}
.range-betting-container:hover{
    cursor: pointer;
}
.range-betting-header{
    width:100%; height:50%; padding-top:10px;background-color: rgba(32, 218, 88, 0.2);
    border-bottom: 3px solid rgb(218, 165, 32); 
    color: lightgoldenrodyellow;
    text-shadow: 1px 0px black;
    padding-top: 0px;
    font-weight: 700;
    font-size: 27px;
}
.range-betting-payout{
    text-align:center;
    font-size: .8rem;
}
.img-coin-range{
    width: 100%;
    display: grid;
    justify-content: center;
}
.result-betting-loto{
    color: rgb(218, 165, 32);
    font-weight: 900;
    font-size:31px

}
</style>